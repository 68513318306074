export const environment = {
  production: true,
  buildConfiguration: 'staging',
  app_name: 'fibr',
  version: '0.0.1-MPV',
  localization: 'en',
  apiEndpoint: 'fibr',
  logo: 'assets/images/logo_appfibr.svg',
  logo_right: '/assets/logo_appfibr-w.svg',
  logo_footer: '/assets/logo_fibr_footer.svg',
  image_login: '/assets/images/img_login-forgot-fibr-new.jpg',
  image_register: '/assets/images/img_register-fibr-new.jpg',
  image_forgotPassword: '/assets/images/img_login-forgot-fibr-new.jpg',
  isGoogleSignIn: true,
  isAppleSignIn: true,
  isMicrosoftSignIn: true,
  registerNameLabel_en: 'Full Name',
  registerNameLabel_id: 'Nama Lengkap',
  role: 'partner',
  role_id: 'wzbcwFfUZF3Li2buWD6b',
  nextStage: { isNextPage: false, url: '', queryParams: {} },
  template_sample_data : 'ON1KIIgqRslEr1TsgfXe',
  topbar: {
    isNotif: true,
    isLanguage: true,
    isHelp: true,
    isGreeting: false,
    isProfile: true,
  },
  addPackage: {
    max_rows_free: 100,
    max_rows_paid: 5000,
    max_page_free: 3,
    max_page_paid: 10,
    max_table_free: 3,
    max_table_paid: 20,
    min_upload: 512000,
    max_upload: 20480000,
    max_etc_upload: 2048000,
  },
  login_right: {
    h4: 'Create Your App Without Code!',
    h4_register: 'Create an Account',
    h4_forgotPassword: 'Oops... Forgot your password?',
    h4_admin: 'Simple! Start with AppFibr',
    p: 'Simply! Just prepare your data with Google Sheets, import it to our dashboard, then you can easily create and customize your own mobile app.\r\nLet`s having fun and creative on making any apps with us!',
    p_admin:
      "It's easy to create your own apps. Let's have fun on making any apps with us!",
  },
  config: {
    app_name: 'fibr',
    login_style: 1,
    background_image: '',
    username: 'fadjrin@gmail.com',
    password: '123456',
  },
  gradient: ['#8DE4EA', '#084B83'],
  primary_button_color: '#4394D8',
  text_color: '#479DB8',
  firebase: {
    apiKey: "AIzaSyAWVmTeqANTSrzuGt46ESi78vmFtcmt3yY",
    authDomain: "appfibr-auth-staging.firebaseapp.com",
    projectId: "appfibr-auth-staging",
    storageBucket: "appfibr-users-bucket-staging",
    messagingSenderId: "538706403824",
    appId: "1:538706403824:web:778f62cf9b3c5c63ae3d35",
    measurementId: "G-522GHZK42J"
  },
  firebase2: {
    apiKey: "AIzaSyBCIpQPEPgHU5YoSMG_6JMSUDiUDk58wsQ",
    authDomain: "appfibr-projects-staging.firebaseapp.com",
    projectId: "appfibr-projects-staging",
    storageBucket: "appfibr-bucket-staging-01",
    messagingSenderId: "132875523562",
    appId: "1:132875523562:web:262d42c0f09c7bc3fbce93",
    measurementId: "G-MV8RZ0J801"
  },
  apiUrl: 'https://asia-southeast2-appfibr-auth-staging.cloudfunctions.net',
  previewUrl: 'https://appfibr-projects-staging.web.app',
  apiProjectUrl: 'https://asia-southeast2-appfibr-projects-staging.cloudfunctions.net',
  play_store_app : 'https://play.google.com/store/apps',
  app_store_app : 'https://apps.apple.com/us/app/',

  recaptcha: {
    siteKey: '6LffdIEpAAAAAD1Q8GSN5nzF1wDCQCymxqDRS-EG',
  },
  flutterBundleVersion: '008',
  flutterAssetUrl: 'https://storage.googleapis.com/appfibr-admin-dev.appspot.com/flutter-preview/staging/',
  googleClientID : '132875523562-o7kd8tjd4lj8vmi19p65qm2smgo54bim.apps.googleusercontent.com',
  googleApiKey : 'AIzaSyBCIpQPEPgHU5YoSMG_6JMSUDiUDk58wsQ',
  mixpanel:{
    token: '88f6cea7a9919edf7295194c7d73c826',
  },
};
